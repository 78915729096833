import { AUTO_QUALITY_MODE, BACKGROUND_KEY } from 'constants/settings';

import React, { useCallback, useEffect, useState } from 'react';
import {
  FocusContext,
  useFocusable,
} from '@noriginmedia/norigin-spatial-navigation';
import { ContentRowTitle } from 'components/ContentRow/styled';
import Asset from 'components/Asset/Asset';
import { ContentWrapper, ScrollingRows } from 'styled';
import Button from 'components/Button/Button';

import { DebagOutput, QualityBox, SubTitle } from './styled';

const BACKGROUND_PATH = '/images/';

interface SettingsPageProps {
  setBackgroundIndex: (index: number) => void;
  backgroundIndex: number;
}

const SettingsPage = ({
  setBackgroundIndex,
  backgroundIndex,
}: SettingsPageProps) => {
  const { ref, focusKey } = useFocusable();
  const [keyDebug, setKeyDebug] = useState<string>('{}');
  const [autoQualityMode, setAutoQualityMode] = useState(
    localStorage.getItem(AUTO_QUALITY_MODE) ?? 'auto',
  );

  const onKeydown = useCallback((event: KeyboardEvent) => {
    setKeyDebug(
      JSON.stringify(
        {
          key: event.key,
          keyCode: event.keyCode,
          code: event.code,
          altKey: event.altKey,
          ctrlKey: event.ctrlKey,
          shiftKey: event.shiftKey,
          which: event.which,
        },
        null,
        2,
      ),
    );
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', onKeydown);
    return () => {
      document.removeEventListener('keydown', onKeydown);
    };
  }, []);

  const onAssetFocus = useCallback(
    ({ y }: { y: number }) => {
      ref.current.scrollTo({
        top: y,
        behavior: 'smooth',
      });
    },
    [ref],
  );

  const changeBackground = useCallback((index: number) => {
    setBackgroundIndex(index);
    localStorage.setItem(BACKGROUND_KEY, index.toString());
  }, []);

  const changeAutoQualityMode = (mode: 'auto' | 'best') => {
    localStorage.setItem(AUTO_QUALITY_MODE, mode);
    setAutoQualityMode(mode);
  };

  return (
    <FocusContext.Provider value={focusKey}>
      <ContentWrapper>
        <ContentRowTitle>Налаштування</ContentRowTitle>
        <ScrollingRows ref={ref}>
          <SubTitle>Фон додатку</SubTitle>
          {[...Array(6)].map((_, index) => (
            <Asset
              key={index}
              focusKey={`background-${index}`}
              title={`Фон ${index + 1}`}
              image={`${BACKGROUND_PATH}${index + 1}.jpg`}
              onFocus={onAssetFocus}
              height={100}
              isSelected={index + 1 === backgroundIndex}
              onEnterPress={() => changeBackground(index + 1)}
            />
          ))}
          <SubTitle>Дебаг клавіш</SubTitle>
          <DebagOutput>{keyDebug}</DebagOutput>
          <SubTitle>Авто вибір якості відео</SubTitle>
          <QualityBox>
            <Button
              onEnterPress={() => changeAutoQualityMode('auto')}
              selected={autoQualityMode === 'auto'}
            >
              Залежно від швидкосі мережі
            </Button>
            <Button
              onEnterPress={() => changeAutoQualityMode('best')}
              selected={autoQualityMode === 'best'}
            >
              Найкраща якість
            </Button>
          </QualityBox>
          <SubTitle>Додаткові функції</SubTitle>
          <Button
            onEnterPress={() =>
              location.replace('https://beta.movies-app-e5u.pages.dev/')
            }
          >
            Відкрити бета версію
          </Button>
        </ScrollingRows>
      </ContentWrapper>
    </FocusContext.Provider>
  );
};

export default SettingsPage;
